import React, { memo as Memo, useRef, useEffect } from 'react';
import { gsap } from 'gsap'

//* Helpers
import isMobile from '../../../../helpers/isMobile'

const AwardsArrow = Memo(({ active }) => {

    //! Refs
    const arrRef = useRef()
    const lineRef = useRef()
    const pathRef = useRef()

    const tl1 = useRef()
    const tl2 = useRef()

    useEffect(() => {
        if (!isMobile()) {
            tl1.current = gsap
                .timeline({
                    paused: true
                })
                .to(lineRef.current, 1, {
                    strokeDashoffset: 800, ease: "power1.inOut"
                });

            tl2.current = gsap
                .timeline({
                    paused: true
                })
                .to(arrRef.current, {
                    duration: 1,
                    ease: "power1.inOut",
                    motionPath: {
                        path: pathRef.current,
                        align: pathRef.current,
                        autoRotate: true,
                        start: 0.7,
                        end: -0.1,
                        alignOrigin: [0.9, 0.3]
                    }
                })

            return () => {
                tl1.current && tl1.current.kill()
                tl2.current && tl2.current.kill()
            }
        }
    }, [])


    useEffect(() => {
        if (!isMobile()) {
            if (active) {
                tl1.current.play(), tl2.current.play()
            }
            else {
                tl1.current.reverse(), tl2.current.reverse()
            }
        }
    }, [active, tl1, tl2])

    return (
        <svg preserveAspectRatio="none" viewBox="0 0 75.88 64.73">

            <polyline ref={arrRef} fill="none" points="63.9,63.5 67.5,49.7 53.5,45.9 		" />

            <path ref={pathRef} fill="none" stroke="none" d="M74.5,2.5c-2.5,1.2-4.9,3.9-5.8,5c-4.2,4.5-6,6.4-6.5,6.6c-21,11.6-61,7.7-61.1-0.2C1,3.3,32.1,3.9,41.7,4.8
		c7.7,0.7,28.4,5,29,21.2C70,51,1,44.9,1,35.3c0-8,36.8-7,36.8-7c13.1,0,33.8,5.2,33.6,15.5c0,2-1.3,5.5-3.9,5.9"/>
            <path ref={lineRef} fill="none" d="M74.4,1.4c-4,4.6-6.8,8.3-11,12c-0.8,0.7-1.9,1.2-2.9,1.7C41.2,25.7,1.2,21.8,1.1,13.9C1,3.3,32.1,4,41.6,4.8
		c7.8,0.7,28.5,5,29.1,21.2C70,51,1,44.9,1,35.3c0-8,36.8-7,36.8-7c13.1,0,33,4.8,32.8,15.1c0.1,2.4-1,4.6-3.1,6.4
		c-10.9,9.9-47.7,12.1-64.6,1.3"/>
        </svg>
    )
})
export default AwardsArrow;

