import React, { memo as Memo, useMemo } from 'react'
import dynamic from 'next/dynamic'

//* HOCs
import widthLanguageContext from '../../../context/consumerHOC/LanguageConsumer'


//*Components
import HTMLContainer from '../HTMLContainer'
import Awards from '../Awards'
import PageTopSection from '../PageTopSection'
import Button from '../Button'
const CursorParticles = dynamic(import('../CursorParticles'), { ssr: false })

//*style 
import style from './style.module.scss'

const AwardsCont = Memo(({ data, anime, active, global, type, count, parentClassName, className, link, translate }) => {

    const awardsProps = useMemo(() => {
        const obj = {
            type: type,
            count: count,
            anime: anime
        }

        return global ? { ...obj, global: global } : obj
    }, [type, count, global, anime])

    return (
        <div className={`${style.awardsCont} ${parentClassName || ''} ${type === 1 ? style.norm : style.black}`}>
            <HTMLContainer>
                <div className={`section-item ${className || ''}`}>
                    <PageTopSection className="aw-section" subtitleTag="p" titleTag="h4" {...data} />

                    <Awards className="aw-blocks" active={active}  {...awardsProps} />

                    <Button link={link} customClass='load-more' text={translate("weHaveMoreHere")} type={type === 1 ? 1 : ""} />

                </div>
            </HTMLContainer>

            <CursorParticles />
        </div>
    )
})

export default widthLanguageContext(AwardsCont, ['translate']);