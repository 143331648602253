import React, { memo as Memo, useState, useMemo } from 'react'

//* Components
import Text from '../../Text'

//* SVG
import AwardsArrow from '../AwardsArrow'

//* Styles
import style from './style.module.scss'

const AwardsWhite = Memo(({ ...item }) => {
    //! States
    const [act, setAct] = useState(false)

    const elementInner = useMemo(() => (
        <>
            <div className={style.awardsItemCont} >

                <div className={style.awardsItemInfo1}>
                    <Text tag={"h4"} className={"awardName font-poppins font-regular"}>
                        {item.title}
                    </Text>

                    <Text className={`p-m font-poppins font-light ${style.awardsItemDescription}`}>
                        {item.description}
                    </Text>
                </div>

                <div className={style.awardsItemInfo2}>
                    <Text className={`p-m font-poppins font-light ${style.awardsYers}`}>
                        {item.year}
                    </Text>

                    <div className={`crCircle ${style.awardsLink}`}>
                        <Text className={"p-m font-poppins font-light"}>
                            {item.project_url.replace(/(^\w+:|^)\/\//, '')}
                        </Text>
                    </div>
                </div>
            </div>

            <div className={style.awardsItemWrapIcon}>
                <AwardsArrow active={act} />
            </div>
        </>
    ), [act])


    return item.award_url ?
        (
            <a href={item.award_url} target="_blank" rel="noopener noreferrer" className={style.awardsItemColumn} onMouseEnter={() => setAct(true)} onMouseLeave={() => setAct(false)}>
                {elementInner}
            </a>
        ) :
        (
            <div className={style.awardsItemColumn} onMouseEnter={() => setAct(true)} onMouseLeave={() => setAct(false)}>
                {elementInner}
            </div>
        )
})

export default AwardsWhite;