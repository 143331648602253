import { forwardRef, useRef, useImperativeHandle, useState } from 'react';
import NextImage from 'next/image';

//* Style
import style from './style.module.scss';

const Image = forwardRef(({ src, alt, className, priority = true, onLoad }, ref) => {
	//! Ref
	const imgRef = useRef();

	//! State
	const [loaded, setLoaded] = useState(false);
	useImperativeHandle(ref, () => imgRef.current, []);

	//! Handle Image Load
	const onLoadCallback = () => {
		setLoaded(true);
		typeof onLoad === 'function' && onLoad(loaded);
	};

	//! Check
	const image = typeof src === 'string' ? src.replace('//images', 'https://images') : src;

	return (
		<div
			ref={imgRef}
			className={`${style.imageCont} ${className || ''}`}
			style={{ backgroundColor: loaded ? 'transparent' : '#ECEDEE' }}>
			<div className={style.image}>
				<NextImage
					fill
					unoptimized
					draggable={false}
					priority={priority}
					alt={alt || 'image'}
					sizes='(max-width: 767px) 100%'
					onLoadingComplete={onLoadCallback}
					style={{ opacity: loaded ? 1 : 0 }}
					loading={priority ? 'eager' : 'lazy'}
					src={image || '/images/default.jpeg'}
				/>
			</div>
		</div>
	);
});

export default Image;
